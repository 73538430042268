import { Logo } from '@care/react-component-lib';

import { Box } from '@mui/material';
import TopNavigationDW from '@/components/shared/HomePageTopNavigation/TopNavigationDW';
import TopNavigationMW from '@/components/shared/HomePageTopNavigation/TopNavigationMW';
import SEOMegaNavDT from '@/components/shared/SEOMegaNavDT';
import SEOMegaNavMW from '@/components/shared/SEOMegaNavMW';
import useTestFlags from '@/components/hooks/useTestFlags';
import useResponsive from '@/components/hooks/useResponsive';
import { LAYOUTS } from '@/constants';
import { Layout, SxClassProps } from '@/types';

interface HeaderProps {
  layout: Layout;
  toggleModal?: () => void;
}

const getClasses = (layout: Layout): SxClassProps => ({
  root: [
    (theme) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor:
        layout === LAYOUTS.valueDrivers || layout === LAYOUTS.rebranding
          ? theme.palette.care?.white
          : theme.palette.care?.grey[50],
      position: 'relative',
      maxWidth: layout === LAYOUTS.rebranding ? 'none' : '1440px',
      width: '100%',
      padding: {
        xs:
          layout === LAYOUTS.valueDrivers || layout === LAYOUTS.rebranding
            ? theme.spacing(2, 1.5)
            : theme.spacing(1, 3),
        sm: theme.spacing(
          layout === LAYOUTS.valueDrivers || layout === LAYOUTS.rebranding ? 3 : 4,
          4
        ),
      },
    }),
  ],
  careIcon: (theme) => ({
    height: '40px',
    width: '144px',
    marginTop: theme.spacing(0.5),

    '& g': {
      fill: theme.palette.care?.grey[800],
      [theme.breakpoints.up('md')]: {
        fill: theme.palette.care?.white,
      },
    },
  }),
});

const HeaderWithSEOMegaNav = (props: HeaderProps) => {
  const { toggleModal, layout } = props;
  const { isDesktop } = useResponsive();
  const { ttpAssetUrl } = useTestFlags();
  const classes = getClasses(layout);
  const logoUrl = ttpAssetUrl?.value || '/s/d/ttp/care-logo.svg';

  return (
    <Box component="nav" sx={classes.root} data-testid="header">
      {isDesktop ? (
        <>
          <Logo altLogoUrl={logoUrl} variant="careAlt" width={144} height={40} />
          <TopNavigationDW verticalsView isZipCodeLayoutView toggleModal={toggleModal} />
          <SEOMegaNavDT isZipcodeLayout />
        </>
      ) : (
        <>
          <SEOMegaNavMW layout={layout} />
          <Logo altLogoUrl={logoUrl} variant="careAlt" height={32} width={115} />
          <TopNavigationMW verticalsView toggleModal={toggleModal} layout={layout} />
        </>
      )}
    </Box>
  );
};

export default HeaderWithSEOMegaNav;
