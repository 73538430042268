import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  DEFAULT_VERTICAL,
  SITTER_SMB_ENROLL,
  VERTICALS,
  VERTICALS_NAMES,
  VerticalSelection,
  UNKNOWN_VERTICAL,
} from '@/constants';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, ScreenName } from '@/types/enums';

import useLoginLink from '@/components/hooks/useLoginLink';
import useResponsive from '@/components/hooks/useResponsive';
import VERTICALS_INFO from '@/components/shared/VerticalsInfo';
import ModalMemberSelection from './ModalMemberSelection';
import DrawerMemberSelection from './DrawerMemberSelection';

type MemberSelectionProps = {
  selectedVertical: VerticalSelection;
  openModalOrDrawer: boolean;
  setOpenModalOrDrawer: Dispatch<SetStateAction<boolean>>;
  isVhpMilestone1?: boolean;
};

const verticalsInfo = VERTICALS_INFO({ isDaycareRemoved: true });

function MemberSelection({
  selectedVertical,
  setOpenModalOrDrawer,
  openModalOrDrawer,
  isVhpMilestone1,
}: MemberSelectionProps) {
  const { showEnrollmentLoginLink, fireLoginLinkTestExposure } = useLoginLink();
  const { isDesktop } = useResponsive();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);
  const [seniorCareSteps, setSeniorCareSteps] = useState(false);

  const renderModal = () => {
    fireLoginLinkTestExposure();

    if (isDesktop) {
      setIsModalOpen(true);
    } else {
      setIsBottomDrawerOpen(true);
    }
  };

  useEffect(() => {
    if (openModalOrDrawer) {
      renderModal();
    }
    // reset the var to prepare for another click
    setOpenModalOrDrawer(false);
  }, [openModalOrDrawer]);

  const getSelectedVerticalInfo = (verticalOpt?: string) => {
    return verticalsInfo.find((item) => {
      return item.vertical === selectedVertical || item.vertical === verticalOpt;
    });
  };

  const handleModalClose = () => {
    setSeniorCareSteps(false);
    setIsModalOpen(false);
  };

  const handleVerticalClick = (modalButtonText: string) => {
    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        cta_text: modalButtonText,
        cta_location: CtaLocation.VHP_MODAL,
        vertical: VERTICALS_NAMES[selectedVertical],
        ...(isVhpMilestone1 && {
          screen_name: ScreenName.VISITOR_HOMEPAGE,
          enrollment_initiated: true,
        }),
      },
    });
  };

  const useableIcon =
    selectedVertical !== DEFAULT_VERTICAL && selectedVertical !== UNKNOWN_VERTICAL
      ? getSelectedVerticalInfo()?.valueDriversIcon
      : getSelectedVerticalInfo(VERTICALS.CHILD_CARE)?.valueDriversIcon;

  if (isDesktop) {
    return (
      <ModalMemberSelection
        logVerticalClick={handleVerticalClick}
        selectedVertical={selectedVertical}
        selectedVerticalIcon={useableIcon}
        isModalOpen={isModalOpen}
        handleModalOnClose={handleModalClose}
        seniorCareSteps={seniorCareSteps}
        setSeniorCareSteps={setSeniorCareSteps}
        {...(selectedVertical === VERTICALS.SENIOR_CARE && {
          zipCodeSMBURL: SITTER_SMB_ENROLL,
        })}
        showLoginLink={showEnrollmentLoginLink}
      />
    );
  }

  return (
    <DrawerMemberSelection
      setIsBottomDrawerOpen={setIsBottomDrawerOpen}
      isBottomDrawerOpen={isBottomDrawerOpen}
      logVerticalClick={handleVerticalClick}
      selectedVertical={selectedVertical}
      selectedVerticalIcon={useableIcon}
      showLoginLink={showEnrollmentLoginLink}
    />
  );
}

export default MemberSelection;
