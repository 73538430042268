import { ReactChild } from 'react';
import getConfig from 'next/config';
import { Icon24InfoCaregiver, Icon48BrandSmb } from '@care/react-icons';
import { Modal } from '@care/react-component-lib';
import { VHPDataPublisher, VHPDataRetriever, ComeFrom } from '@care/mfe-data-transfer-vhp';
import { Grid, Button, Chip, useTheme, Box } from '@mui/material';
import { SxClassProps } from '@/types';
import {
  SEEKER_ENROLL,
  SENIOR_CARE_SITTER_TYPE,
  VERTICALS,
  MODAL_CTA_TEXTS,
  DEFAULT_VERTICAL,
  SEEKER_VERTICAL_TRIAGE_PAGE,
  SITTER_DEFAULT_VERTICAL_DT_ENROLL,
  SITTER_MV_ENROLL,
  SITTER_SMB_ENROLL,
  UNKNOWN_VERTICAL,
  VerticalSelection,
} from '@/constants';
import useSeniorCareRedirection from '@/components/hooks/useSeniorCareRedirection';
import useTestFlags from '@/components/hooks/useTestFlags';
import LoginLink from '@/components/shared/LoginLink/LoginLink';

const classes: SxClassProps = {
  modalInfo: {
    margin: '0 30px',
  },
  buttonContainer: {
    marginTop: '-24px',
    marginBottom: '-24px',
    paddingTop: 3,
    display: 'flex',
    justifyContent: 'center',
  },
  tutoringChips: (theme) => ({
    width: '100%',
    height: '72px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 3,
    marginBottom: 2,
    fontSize: '18px',
    fontWeight: 'bold',

    '& svg, & img': {
      marginRight: 1,
    },

    '&:active, &.default': {
      backgroundColor: `${theme.palette.care?.navy[200]} !important`,
      border: theme.palette.care?.navy[200],
      boxShadow: 'none',
    },
  }),
  userTypeBtn: {
    marginRight: 2,
    marginLeft: 2,
    width: '166px',
  },
  floralIcons: (theme) => ({
    '& [data-name="Pet care"]>path:first-of-type': {
      fill: theme.palette.care?.blue[500],
    },
  }),
  modalIcon: (theme) => ({
    position: 'absolute',
    top: '0',
    width: '72px',
    height: '72px',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    background: 'white',
    borderRadius: '40px',
    border: `2px solid ${theme.palette.care?.grey[100]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  modalRoot: {
    '& .MuiDialogContent-root': {
      '& .text-content-wrapper': {
        paddingTop: 2,
      },
    },
  },
  loginLinkWrapper: {
    marginTop: 4,
  },
};

type ModalMemberSelectionProps = {
  logVerticalClick: (ctaText: string) => void;
  selectedVertical: VerticalSelection;
  selectedVerticalIcon: ReactChild | null | undefined;
  isModalOpen: boolean;
  handleModalOnClose: () => void;
  seniorCareSteps: boolean;
  setSeniorCareSteps?: (arg: boolean) => void;
  showLoginLink?: boolean;
};

function ModalMemberSelection({
  logVerticalClick,
  selectedVertical,
  selectedVerticalIcon,
  isModalOpen,
  handleModalOnClose,
  seniorCareSteps,
  setSeniorCareSteps,
  showLoginLink,
}: ModalMemberSelectionProps) {
  const theme = useTheme();
  const scRedirectionLink = useSeniorCareRedirection();
  const { vhpTriageProviderVertical } = useTestFlags();
  const routeProviderToTriagePage = vhpTriageProviderVertical?.value === 2;
  const VHPPublisher = new VHPDataPublisher();
  const VHPRetriever = new VHPDataRetriever();
  const existingData = VHPRetriever.retrieveData();

  const isTutoringFlow = selectedVertical === VERTICALS.TUTORING;
  const {
    publicRuntimeConfig: { CZEN_GENERAL },
  } = getConfig();

  const modalButtonsText = {
    provider: MODAL_CTA_TEXTS.PROVIDER_TEXT,
    seeker: isTutoringFlow ? MODAL_CTA_TEXTS.SEEKER_TUTOR : MODAL_CTA_TEXTS.SEEKER_TEXT,
  };

  const handleDTSeeker = () => {
    const data = { ...existingData, comeFrom: ComeFrom.VHP };

    VHPPublisher.publishData(data);
    logVerticalClick('Find care');
    if (selectedVertical === DEFAULT_VERTICAL || selectedVertical === UNKNOWN_VERTICAL) {
      window.location.assign(`${CZEN_GENERAL}${SEEKER_VERTICAL_TRIAGE_PAGE}`);
    } else {
      window.location.assign(`${CZEN_GENERAL}${SEEKER_ENROLL[selectedVertical]}`);
    }
  };
  const handleDTSitter = () => {
    const data = { ...existingData, comeFrom: ComeFrom.VHP };

    VHPPublisher.publishData(data);
    if (selectedVertical === DEFAULT_VERTICAL || selectedVertical === UNKNOWN_VERTICAL) {
      if (routeProviderToTriagePage) {
        window.location.assign(`${CZEN_GENERAL}${SEEKER_VERTICAL_TRIAGE_PAGE}?memberType=sitter`);
      } else {
        window.location.assign(`${CZEN_GENERAL}${SITTER_DEFAULT_VERTICAL_DT_ENROLL}`);
      }
    } else if (selectedVertical === VERTICALS.SENIOR_CARE && setSeniorCareSteps !== undefined) {
      setSeniorCareSteps(true);
    } else {
      window.location.assign(`${CZEN_GENERAL}${SITTER_MV_ENROLL[selectedVertical]}`);
    }
    logVerticalClick('Find a job');
  };

  const handleSeniorCareSitter = (type?: string) => {
    if (type === SENIOR_CARE_SITTER_TYPE.SMB) {
      window.location.assign(`${CZEN_GENERAL}${SITTER_SMB_ENROLL}`);
    } else {
      window.location.assign(scRedirectionLink);
    }
  };

  return (
    <Modal
      onClose={handleModalOnClose}
      open={isModalOpen}
      hideClose
      maxWidth="xs"
      sx={classes.modalRoot}
      title={seniorCareSteps ? 'Are you an individual or small business?' : 'I would like to:'}>
      <Box sx={classes.modalIcon}>{selectedVerticalIcon}</Box>
      <Box component="span" sx={classes.modalInfo}>
        {seniorCareSteps ? (
          <>
            <Chip
              icon={<Icon24InfoCaregiver size="40px" color={theme.palette?.care?.grey[900]} />}
              label="Individual"
              data-testid="sc-individual"
              variant="outlined"
              onClick={() => handleSeniorCareSitter(SENIOR_CARE_SITTER_TYPE.INDIVIDUAL)}
              sx={classes.tutoringChips}
            />
            <Chip
              icon={<Icon48BrandSmb size="40px" color={theme.palette?.care?.grey[900]} />}
              label="Small business"
              data-testid="sc-small-business"
              variant="outlined"
              onClick={() => handleSeniorCareSitter(SENIOR_CARE_SITTER_TYPE.SMB)}
              sx={classes.tutoringChips}
            />
          </>
        ) : (
          <Grid container sx={classes.buttonContainer} direction="row">
            <Button
              color="primary"
              variant="contained"
              data-testid="findACaregiver"
              sx={classes.userTypeBtn}
              onClick={handleDTSeeker}>
              {modalButtonsText.seeker}
            </Button>
            <Button
              color="primary"
              variant="contained"
              data-testid="findAJob"
              sx={classes.userTypeBtn}
              onClick={handleDTSitter}>
              {modalButtonsText.provider}
            </Button>
          </Grid>
        )}
      </Box>
      {showLoginLink && (
        <Box sx={classes.loginLinkWrapper}>
          <LoginLink ctaLocation="VHP Modal" vertical={selectedVertical} />
        </Box>
      )}
    </Modal>
  );
}

export default ModalMemberSelection;
