import {
  Icon48BrandChildcare,
  Icon48BrandDaycareCenters,
  Icon48BrandHousekeeping,
  Icon48BrandPetCare,
  Icon48BrandSeniorCare,
  Icon48BrandTutoring,
  IconIllustrationSmallChildCare,
  IconIllustrationSmallDaycareCenters,
  IconIllustrationSmallHousekeeping,
  IconIllustrationSmallPetCare,
} from '@care/react-icons';

import CustomIconIllustrationSmallSeniorCare from '@/components/svg/CustomIconIllustrationSmallSeniorCare';
import CustomIconIllustrationSmallTutoring from '@/components/svg/CustomIconIllustrationSmallTutoring';
import { VERTICALS, VERTICALS_DISPLAY_NAMES } from '@/constants';
import {
  ValueDriversTutoringIcon,
  ValueDriversSeniorCareIcon,
  ValueDriversPetCareIcon,
  ValueDriversHousekeepingIcon,
  ValueDriversChildCareIcon,
} from '@/components/svg/ValueDrivers';

type verticalsDisplayInfoProp = {
  iconSize?: number;
  iconColor?: string;
  isDaycareRemoved?: boolean;
};

const verticalsDisplayInfo = (prop?: verticalsDisplayInfoProp) => {
  const additionalProps = {
    size: `${prop?.iconSize || 48}px`,
    ...(prop?.iconColor && { color: prop?.iconColor }),
  };

  const childcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.CHILD_CARE],
    icon: <Icon48BrandChildcare {...additionalProps} />,
    vertical: VERTICALS.CHILD_CARE,
    floralDesignIcon: <IconIllustrationSmallChildCare size="48px" />,
    valueDriversIcon: <ValueDriversChildCareIcon />,
  };

  const seniorcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.SENIOR_CARE],
    icon: <Icon48BrandSeniorCare {...additionalProps} />,
    vertical: VERTICALS.SENIOR_CARE,
    floralDesignIcon: <CustomIconIllustrationSmallSeniorCare />,
    valueDriversIcon: <ValueDriversSeniorCareIcon />,
  };

  const tutoring = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.TUTORING],
    icon: <Icon48BrandTutoring {...additionalProps} />,
    vertical: VERTICALS.TUTORING,
    floralDesignIcon: <CustomIconIllustrationSmallTutoring />,
    valueDriversIcon: <ValueDriversTutoringIcon />,
  };

  const housekeeping = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.HOUSEKEEPING],
    icon: <Icon48BrandHousekeeping {...additionalProps} />,
    vertical: VERTICALS.HOUSEKEEPING,
    floralDesignIcon: <IconIllustrationSmallHousekeeping size="48px" />,
    valueDriversIcon: <ValueDriversHousekeepingIcon />,
  };

  const daycare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.DAY_CARE],
    icon: <Icon48BrandDaycareCenters {...additionalProps} />,
    vertical: VERTICALS.DAY_CARE,
    floralDesignIcon: <IconIllustrationSmallDaycareCenters size="48px" />,
    valueDriversIcon: undefined,
  };

  const petcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.PET_CARE],
    icon: <Icon48BrandPetCare {...additionalProps} />,
    vertical: VERTICALS.PET_CARE,
    floralDesignIcon: <IconIllustrationSmallPetCare size="48px" />,
    valueDriversIcon: <ValueDriversPetCareIcon />,
  };

  return prop?.isDaycareRemoved
    ? [childcare, seniorcare, housekeeping, petcare, tutoring]
    : [childcare, seniorcare, housekeeping, petcare, daycare, tutoring];
};
export default verticalsDisplayInfo;
