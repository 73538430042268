import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import dynamic from 'next/dynamic';

import { Box, useTheme } from '@mui/material';
import logger from '@/lib/clientLogger';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import {
  CZEN_SESSION_COOKIE_KEY,
  CZEN_VISITOR_COOKIE_KEY,
  LAYOUTS,
  DEFAULT_VERTICAL,
  GTM_SLOTS,
  VerticalSelection,
  CLIENT_FEATURE_FLAGS,
  LAYOUT_VARIANTS,
} from '@/constants';
import PromocodeRibbon from '@/components/shared/PromocodeRibbon/PromocodeRibbon';
import usePromocodeRibbon from '@/components/hooks/usePromocodeRibbon';
import NewsScroll from '@/components/shared/NewsScroll/NewsScroll';
import useResponsive from '@/components/hooks/useResponsive';
import HomePageFooter from '@/components/shared/HomePageFooter';
import useRxCodes from '@/components/hooks/useRxCodes';
import HeaderWithSEOMegaNav from '@/components/shared/HeaderWithSEOMegaNav';
import MemberSelection from '@/components/shared/MemberSelection';
import withLazyLoad from '@/utilities/withLazyLoad';
import useTestFlags from '@/components/hooks/useTestFlags';
import WaveDivider from '@/components/shared/WaveDivider/WaveDivider';
import Hero from './Hero';
import VerticalSelectionWithWaveDivider from './VerticalSelection/VerticalSelectionWithWaveDivider';

// Dettaching this code from the actual bundle
const CaregiverReviews = dynamic(() => import('./CaregiverReviews'), { ssr: true });
const HowItWorks = dynamic(() => import('./HowItWorks'), { ssr: true });
const SafetyOptions = dynamic(() => import('./SafetyOptions'), { ssr: true });
const ValueDrivers = dynamic(() => import('./ValueDrivers'), { ssr: true });

// NOTE: with dynamic imports the Component default displayName is not available and Next's generic name "LoadableComponent" is assigned
// For better debugging define a unique display name
CaregiverReviews.displayName = 'LoadableComponent.CaregiverReviews';
HowItWorks.displayName = 'LoadableComponent.HowItWorks';
SafetyOptions.displayName = 'LoadableComponent.SafetyOptions';
ValueDrivers.displayName = 'LoadableComponent.ValueDrivers';

// Using HOC withLazyLoad to load the component when is on screen
const CaregiverReviewsWithLazyLoad = withLazyLoad(CaregiverReviews);
const HowItWorksWithLazyLoad = withLazyLoad(HowItWorks, 0);
const ValueDriversWithLazyLoad = withLazyLoad(ValueDrivers);

const LAYOUT = LAYOUTS.valueDrivers;

function VisitorHomeValueDriversLayout() {
  const theme = useTheme();
  const [selectedVertical, setSelectedVertical] = useState<VerticalSelection>(DEFAULT_VERTICAL);
  const [openModalOrDrawer, setOpenModalOrDrawer] = useState<boolean>(false);
  const { showPromocodeRibbon, promocodeDiscountMessage } = usePromocodeRibbon();
  const { isMobile, isDesktop } = useResponsive();
  const { rxData, rxDataDoneLoading } = useRxCodes();
  const { vhpTriageProviderVertical } = useTestFlags();
  const cookies = new Cookies();
  const czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
  const czenSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY);

  useEffect(() => {
    if (rxDataDoneLoading) {
      AnalyticsHelper.logEvent({
        name: 'Homepage Viewed',
        data: {
          slots: [GTM_SLOTS.VHP],
          seo_page_id: '1000',
          screen_name: 'Homepage',
          ...rxData,
        },
      });
    }

    logger.info({
      name: 'Homepage Viewed',
      data: {
        screen_name: 'Homepage',
        layout: LAYOUT,
        czenVisitorId,
        czenSessionId,
      },
    });
  }, [czenSessionId, czenVisitorId, rxData, rxDataDoneLoading]);

  useEffect(() => {
    AnalyticsHelper.logTestExposure(
      CLIENT_FEATURE_FLAGS.VHP_TRIAGE_PROVIDER_VERTICAL,
      vhpTriageProviderVertical
    );
  }, [vhpTriageProviderVertical]);

  return (
    <>
      {showPromocodeRibbon && <PromocodeRibbon discountMessage={promocodeDiscountMessage} />}
      <Box
        display="flex"
        justifyContent="center"
        borderBottom={`1px solid ${theme.palette.care?.grey[300]}`}>
        <HeaderWithSEOMegaNav layout={LAYOUT} />
      </Box>

      <Box display="flex" justifyContent="center">
        <Hero setSelectedVertical={setSelectedVertical} />
      </Box>

      <VerticalSelectionWithWaveDivider
        setSelectedVertical={setSelectedVertical}
        setOpenModalOrDrawer={setOpenModalOrDrawer}
        layoutVariant={LAYOUT_VARIANTS.triageTabs}
      />

      <CaregiverReviewsWithLazyLoad />

      {/* Credibility bar */}
      <NewsScroll
        layout={LAYOUT}
        layoutVariant={LAYOUT_VARIANTS.triageTabs}
        credibilityBarLogo="/app/vhp/static/oldCredBarBlack.svg"
      />

      {/* How it works (videos) */}
      <WaveDivider
        backgroundColor={theme.palette.care?.white}
        shapeFill={theme.palette.care?.blue[100]}
      />
      <HowItWorksWithLazyLoad />

      {/* Safety Options */}

      <WaveDivider backgroundColor="#f0f6fd" shapeFill={theme.palette.care?.white} flipVertical />
      <SafetyOptions />

      {/* Value Drivers */}
      <WaveDivider
        backgroundColor={theme.palette.care?.white}
        shapeFill={theme.palette.care?.latte[100]}
        flipVertical={!isMobile}
      />
      <ValueDriversWithLazyLoad
        setSelectedVertical={setSelectedVertical}
        setOpenModalOrDrawer={setOpenModalOrDrawer}
      />

      {/* Member selection */}
      <MemberSelection
        selectedVertical={selectedVertical}
        openModalOrDrawer={openModalOrDrawer}
        setOpenModalOrDrawer={setOpenModalOrDrawer}
      />

      <HomePageFooter renderDesktopView={isDesktop} />
    </>
  );
}

export default VisitorHomeValueDriversLayout;
