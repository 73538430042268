import { ReactChild } from 'react';
import getConfig from 'next/config';
import { Grid, Button, Drawer, Typography, Box } from '@mui/material';
import { VHPDataPublisher, VHPDataRetriever, ComeFrom } from '@care/mfe-data-transfer-vhp';

import {
  MODAL_CTA_TEXTS,
  SEEKER_ENROLL,
  SITTER_ENROLL,
  VERTICALS,
  DEFAULT_VERTICAL,
  SEEKER_VERTICAL_TRIAGE_PAGE,
  SITTER_DEFAULT_VERTICAL_MW_ENROLL,
  UNKNOWN_VERTICAL,
  VerticalSelection,
} from '@/constants';

import useTestFlags from '@/components/hooks/useTestFlags';
import useSeniorCareRedirection from '@/components/hooks/useSeniorCareRedirection';
import LoginLink from '@/components/shared/LoginLink/LoginLink';
import { SxClassProps } from '@/types';

const classes: SxClassProps = {
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      paddingTop: 0,
      backgroundColor: 'transparent',
      paddingBottom: 0,
    },
  },
  drawerIcon: (theme) => ({
    backgroundColor: theme.palette.care?.white,
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.care?.grey[100]}`,
    transition: 'top .4s linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: theme.spacing(0, 'auto', 0, 'auto'),
    position: 'relative',
    top: '35px',
  }),
  drawerContent: (theme) => ({
    background: theme.palette.care?.white,
    paddingTop: theme.spacing(7.25),
    borderRadius: theme.spacing(3, 3, 0, 0),
    textAlign: 'center',
    width: 'auto',
    '& button': {
      marginTop: 1.5,
      marginBottom: 3,
      [theme.breakpoints.up(396)]: {
        marginTop: 4,
        marginBottom: 4,
      },
    },
  }),
  drawerText: (theme) => ({
    marginBottom: 2,
    [theme.breakpoints.up(396)]: {
      marginBottom: 0,
    },
  }),
  seekerButton: {
    marginLeft: 1,
    marginRight: 1,
    width: '180px',
  },
  floralIcons: (theme) => ({
    '& [data-name="Pet care"]>path:first-of-type': {
      fill: theme.palette.care?.blue[500],
    },
  }),
  loginLinkWrapper: {
    marginTop: -1,
    marginBottom: 3,
  },
};

type DrawerMemberSelectionProps = {
  setIsBottomDrawerOpen: (arg: boolean) => void;
  isBottomDrawerOpen: boolean;
  logVerticalClick: (ctaText: string) => void;
  selectedVertical: VerticalSelection;
  selectedVerticalIcon: ReactChild | null | undefined;
  showLoginLink?: boolean;
};

function DrawerMemberSelection({
  setIsBottomDrawerOpen,
  isBottomDrawerOpen,
  logVerticalClick,
  selectedVertical,
  selectedVerticalIcon,
  showLoginLink,
}: DrawerMemberSelectionProps) {
  const { vhpTriageProviderVertical } = useTestFlags();

  const scRedirectionLink = useSeniorCareRedirection();
  const {
    publicRuntimeConfig: { CZEN_GENERAL },
  } = getConfig();

  const isTutoringFlow = selectedVertical === VERTICALS.TUTORING;
  const modalButtonsText = {
    provider: MODAL_CTA_TEXTS.PROVIDER_TEXT,
    seeker: isTutoringFlow ? MODAL_CTA_TEXTS.SEEKER_TUTOR : MODAL_CTA_TEXTS.SEEKER_TEXT,
  };
  const routeProviderToTriagePage = vhpTriageProviderVertical?.value === 2;
  const VHPPublisher = new VHPDataPublisher();
  const VHPRetriever = new VHPDataRetriever();
  const existingData = VHPRetriever.retrieveData();

  const handleSeeker = () => {
    const data = { ...existingData, comeFrom: ComeFrom.VHP };

    VHPPublisher.publishData(data);
    logVerticalClick('Find care');

    if (selectedVertical === DEFAULT_VERTICAL || selectedVertical === UNKNOWN_VERTICAL) {
      window.location.assign(`${CZEN_GENERAL}${SEEKER_VERTICAL_TRIAGE_PAGE}`);
    } else {
      window.location.assign(`${CZEN_GENERAL}${SEEKER_ENROLL[selectedVertical]}`);
    }
  };

  const handleSitter = () => {
    const data = { ...existingData, comeFrom: ComeFrom.VHP };

    VHPPublisher.publishData(data);
    logVerticalClick('Find a job');
    if (
      (selectedVertical === DEFAULT_VERTICAL || selectedVertical === UNKNOWN_VERTICAL) &&
      routeProviderToTriagePage
    ) {
      window.location.assign(`${CZEN_GENERAL}${SEEKER_VERTICAL_TRIAGE_PAGE}?memberType=sitter`);
    } else if (selectedVertical === DEFAULT_VERTICAL && !routeProviderToTriagePage) {
      window.location.assign(`${CZEN_GENERAL}${SITTER_DEFAULT_VERTICAL_MW_ENROLL}`);
    } else if (selectedVertical === VERTICALS.SENIOR_CARE) {
      window.location.assign(scRedirectionLink);
    } else {
      window.location.assign(`${CZEN_GENERAL}${SITTER_ENROLL}${selectedVertical}`);
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={isBottomDrawerOpen}
      onClose={() => setIsBottomDrawerOpen(false)}
      sx={classes.drawerWrapper}>
      <Box sx={classes.drawerIcon}>{selectedVerticalIcon}</Box>
      <Box sx={classes.drawerContent}>
        <Typography sx={classes.drawerText} variant="h3">
          I would like to:
        </Typography>
        <Grid container justifyContent="center" direction="row">
          <Button
            color="primary"
            variant="contained"
            size="medium"
            data-testid="findACaregiver"
            sx={classes.seekerButton}
            onClick={handleSeeker}>
            {modalButtonsText.seeker}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            data-testid="findAJob"
            sx={classes.seekerButton}
            onClick={handleSitter}>
            {modalButtonsText.provider}
          </Button>
        </Grid>
        {showLoginLink && (
          <Box sx={classes.loginLinkWrapper}>
            <LoginLink ctaLocation="VHP Modal" vertical={selectedVertical} />
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

export default DrawerMemberSelection;
