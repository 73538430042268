import { VHPDataPublisher, VHPDataRetriever } from '@care/mfe-data-transfer-vhp';
import useTestFlags from '@/components/hooks/useTestFlags';
import { CLIENT_FEATURE_FLAGS } from '@/constants';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';

const useLoginLink = () => {
  const VHPPublisher = new VHPDataPublisher();
  const VHPRetriever = new VHPDataRetriever();
  const { enrollmentLoginLink } = useTestFlags();
  const showEnrollmentLoginLink = enrollmentLoginLink?.value === 2;
  const existingData = VHPRetriever.retrieveData();

  const fireLoginLinkTestExposure = () => {
    const { loginLinkViewedTestFired } = existingData || {};

    if (!loginLinkViewedTestFired) {
      AnalyticsHelper.logTestExposure(
        CLIENT_FEATURE_FLAGS.GROWTH_ENROLLMENT_LOGIN_LINK,
        enrollmentLoginLink
      );
      const data = { ...existingData, loginLinkViewedTestFired: true };
      VHPPublisher.publishData(data);
    }
  };

  return {
    showEnrollmentLoginLink,
    fireLoginLinkTestExposure,
  };
};

export default useLoginLink;
