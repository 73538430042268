import { MouseEvent } from 'react';
import { Box, Link, Typography } from '@mui/material';
import getConfig from 'next/config';

import { TOP_NAV_LINKS, VerticalSelection, VERTICALS_DISPLAY_NAMES } from '@/constants';
import { SxClassProps } from '@/types';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

const classes: SxClassProps = {
  loginLink: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

const LoginLink = ({
  ctaLocation,
  vertical,
}: {
  ctaLocation: string;
  vertical?: VerticalSelection;
}) => {
  const onLogInClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const analyticsData = {
      name: 'CTA Interacted',
      data: {
        cta_text: 'Log in',
        cta_location: ctaLocation,
        vertical: 'Unknown',
      },
    };

    if (vertical && vertical !== 'none' && vertical !== 'unknown') {
      analyticsData.data.vertical = VERTICALS_DISPLAY_NAMES[vertical];
    }
    AnalyticsHelper.logEvent(analyticsData);
    window.location.assign(`${CZEN_GENERAL}${TOP_NAV_LINKS.logIn}`);
  };

  return (
    <Box sx={classes.loginLink}>
      <Typography variant="body2" display="inline">
        Already have a Care account?&nbsp;
      </Typography>
      <Link variant="body2" onClick={onLogInClick} underline="always" sx={classes.link}>
        Log in
      </Link>
    </Box>
  );
};

export default LoginLink;
