import getConfig from 'next/config';
import { useEffect } from 'react';
import { CLIENT_FEATURE_FLAGS, SENIOR_CARE_ENROLLMENT } from '@/constants';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import useTestFlags from './useTestFlags';

const useSeniorCareRedirection = () => {
  const {
    publicRuntimeConfig: { CZEN_GENERAL },
  } = getConfig();
  const { seniorCareRedirection } = useTestFlags();
  useEffect(() => {
    AnalyticsHelper.logTestExposure(
      CLIENT_FEATURE_FLAGS.SC_PROVIDER_ENROLLMENT,
      seniorCareRedirection
    );
  }, [seniorCareRedirection]);
  if (seniorCareRedirection?.variationIndex === 2) {
    return `${CZEN_GENERAL}${SENIOR_CARE_ENROLLMENT.MV}`;
  }
  return `${CZEN_GENERAL}${SENIOR_CARE_ENROLLMENT.MFE}`;
};

export default useSeniorCareRedirection;
