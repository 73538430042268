import { Dispatch, SetStateAction } from 'react';
import { Box, Typography } from '@mui/material';
import { Icon24UtilityChevronSmallRight, Icon24UtilityChevronRight } from '@care/react-icons';

import { SxClassProps } from '@/types';
import VERTICALS_INFO from '@/components/shared/VerticalsInfoValueDriverLayout';
import useResponsive from '@/components/hooks/useResponsive';
import { VerticalSelection as TVerticalSelection, VERTICALS_NAMES } from '@/constants';
import useTestFlags from '@/components/hooks/useTestFlags';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, ScreenName } from '@/types/enums';

const getClasses = (isFlagValue4: boolean): SxClassProps => ({
  wrapper: {
    paddingBottom: {
      xs: 7,
      md: 10,
    },
    paddingX: {
      xs: 3,
      lg: 10,
    },
  },
  verticalsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',

    margin: '0 auto',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(32% - 5px)',
    height: 'auto',
    borderRadius: 4,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    marginRight: {
      xs: 1,
      sm: 4,
      md: 1,
      lg: 4,
    },
    marginBottom: {
      xs: 2,
      sm: 1.5,
    },
    paddingY: 2,
    '&:hover': {
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
    },
    '&:last-child': {
      marginRight: 0,
    },
    '@media (min-width: 600px)': {
      width: '150px',
    },
    '@media (min-width: 700px)': {
      width: '180px',
      flexDirection: 'row',
    },
    '@media (min-width: 806px)': {
      width: '221px',
    },
    '@media (min-width: 960px)': {
      width: '195px',
    },
    '@media (min-width: 1440px)': {
      width: '238px',
      marginRight: '22px',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 1,
  },
  verticalHeadline: {
    textAlign: 'center',
    fontSize: {
      sm: 'h1.fontSize',
      md: isFlagValue4 ? 'h1.fontSize' : 'display3.fontSize',
    },
    lineHeight: {
      sm: '34px',
      md: isFlagValue4 ? '34px' : '42px',
    },
    paddingBottom: { xs: isFlagValue4 ? 0 : 1, sm: 1 },
  },
  verticalSubHeadline: {
    textAlign: 'center',
    fontSize: {
      sm: 'h3.fontSize',
      md: 'h2.fontSize',
    },
    lineHeight: {
      sm: '24px',
      md: '26px',
    },
  },
});

type VerticalSelectionProps = {
  setSelectedVertical: Dispatch<SetStateAction<TVerticalSelection>>;
  setOpenModalOrDrawer: Dispatch<SetStateAction<boolean>>;
};

function VerticalSelection({ setSelectedVertical, setOpenModalOrDrawer }: VerticalSelectionProps) {
  const { isMobile } = useResponsive();
  const { vhpMilestone1 } = useTestFlags();
  const showSubHeader = [2, 3].includes(vhpMilestone1?.value);
  const isFlagValue4 = vhpMilestone1?.value === 4;
  const classes = getClasses(isFlagValue4);

  const headline = isFlagValue4
    ? 'Find background-checked care to help make life work'
    : "Join 29 million families who've turned to Care";

  const verticalsInfo = VERTICALS_INFO({ isDaycareRemoved: true });
  const handleVerticalSelection = (vertical: TVerticalSelection) => {
    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        cta_location: CtaLocation.VHP_BODY,
        vertical: VERTICALS_NAMES[vertical],
        cta_text: VERTICALS_NAMES[vertical],
      },
    });
    setSelectedVertical(vertical);
    setOpenModalOrDrawer(true);
  };

  return (
    <Box sx={classes.wrapper}>
      <Box pt={{ xs: 1, sm: 4, md: 6 }} pb={{ xs: 4 }} px={{ xs: 1, sm: 3 }}>
        <Typography variant="h2" sx={classes.verticalHeadline}>
          {headline}
        </Typography>
        {showSubHeader && (
          <Typography variant="body2" sx={classes.verticalSubHeadline}>
            Background-checked care for all you love.
          </Typography>
        )}
      </Box>
      <Box sx={classes.verticalsContainer}>
        {verticalsInfo.map((option, index) => (
          <Box
            key={option.vertical}
            tabIndex={index}
            role="button"
            sx={classes.item}
            data-testid={option.vertical}
            onClick={() => handleVerticalSelection(option.vertical)}
            onKeyDown={() => handleVerticalSelection(option.vertical)}>
            {option.valueDriversIcon}
            <Box sx={classes.label}>
              <Typography
                variant="h5"
                textAlign="center"
                fontSize={isMobile ? '12px' : '16px'}
                pr={{ xs: 0, sm: 1 }}>
                {option.displayName}
              </Typography>
              {isMobile && <Icon24UtilityChevronSmallRight size={16} />}
              {!isMobile && <Icon24UtilityChevronRight />}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default VerticalSelection;
